import React from "react";
import Layout from "../../components/layout/layout";
import { TextPhotoSection } from "../../components/TextPhotoSection/TextPhotoSection";
import CtaSection from "../../components/CtaSection/CtaSection";
import { Container } from "../../components/Container/Container";
import { Column } from "../../components/Column/Column";
import { graphql, useStaticQuery } from "gatsby";
import { PostFold } from "../../components/PostFold/PostFold";
import { ItemsHolder } from "../../components/ItemsHolder/ItemsHolder";
import { CardServiceItem } from "../../components/CardServiceItem/CardServiceItem";
import { CardQuote } from "../../components/CardQuote/CardQuote";
import { CardMockup } from "../../components/CardMockup/CardMockup";

const IMAGE_QUERY = graphql`
  {
    imagePascal: file(relativePath: { eq: "about-us/pascal-ehlert.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 500
          placeholder: BLURRED
          formats: [AUTO, JPG, WEBP, AVIF]
        )
      }
    }
    wintradoMockup: file(
      relativePath: { eq: "our-work/industries/wintrado-img.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 952
          placeholder: BLURRED
          formats: [AUTO, JPG, WEBP, AVIF]
        )
      }
    }
    AboutUsImg: file(relativePath: { eq: "about-us.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 500
          placeholder: BLURRED
          formats: [AUTO, JPG, WEBP, AVIF]
        )
      }
    }
    manufactoringImage: file(
      relativePath: { eq: "our-work/industries/manufacturing.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          placeholder: BLURRED
          formats: [AUTO, JPG, WEBP, AVIF]
        )
      }
    }
    financeIndustry: file(
      relativePath: { eq: "our-work/industries/finance-industry-inner.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          placeholder: BLURRED
          formats: [AUTO, JPG, WEBP, AVIF]
        )
      }
    }
    goLangIcon: file(relativePath: { eq: "technologies/golang.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: BLURRED
          formats: [AUTO, JPG, WEBP, AVIF]
        )
      }
    }
    rabbitmqIcon: file(relativePath: { eq: "technologies/rabbitmq.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: BLURRED
          formats: [AUTO, JPG, WEBP, AVIF]
        )
      }
    }
    nodejsIcon: file(relativePath: { eq: "technologies/nodejs.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: BLURRED
          formats: [AUTO, JPG, WEBP, AVIF]
        )
      }
    }
    postgreqlIcon: file(relativePath: { eq: "technologies/postgreql.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: BLURRED
          formats: [AUTO, JPG, WEBP, AVIF]
        )
      }
    }
    unitTestingIcon: file(
      relativePath: { eq: "technologies/unit-testing.png" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 200
          placeholder: BLURRED
          formats: [AUTO, JPG, WEBP, AVIF]
        )
      }
    }
  }
`;

const OurWork = () => {
  const tempImage = useStaticQuery(IMAGE_QUERY);
  return (
    <Layout
      pageTitle="Transforming Finance"
      description="Drive a Trusted Future in Financial Services."
      jobPost
    >
      <PostFold
        title="Transforming Finance"
        description="Drive a Trusted Future in Financial Services."
        image={tempImage.financeIndustry}
        backLinkUrl="/our-work"
        backLinkTitle="Our Work"
        wrapHeading
        industryPage
      />
      <Container>
        <Column columns_mobile="12" columns_tablet="12" columns_desktop="12">
          <div className="blog-post">
            <div className="blog-post__main-holder">
              <p>
                In a world where change is constant, keeping pace with new
                digitalization technologies that speed up time to value is key
                to staying competitive. 
              </p>
              <p>
                Yet many firms across the financial services sector still rely
                on legacy trading systems to move data and money. Add this to
                the increasing complexity of regulatory regimes, and the need
                for a fast and accurate approach has never been bigger.
              </p>
              <p>
                Kickstage delivers on the promise of digital transformation with
                ultra-low latency solutions that process high-speed and accurate
                transactions. Our trading solutions are highly scalable and
                tested to the most rigorous standards to ensure the highest
                possible performance and unrivalled uptime.
              </p>
            </div>
          </div>
        </Column>
      </Container>
      <Container modifer="container--small">
        <CardMockup
          image={tempImage.wintradoMockup}
          title="Wintrado - Web application/Trading platform"
          description="Trading platform which features real-time trade processing with the lowest possible latencies, accurate handling of payments and financial transactions."
          link="/about-us"
          linkText="Find out more"
        />
        <CardQuote
          fullName="Pascal Ehlert"
          image={tempImage.imagePascal}
          position="COO, Co-Founder"
          citation="“When hundreds of price updates come in simultaneously over the FIX protocol and need to be distributed to thousands of clients worldwide, that’s where the Kickstage Real Time stack gives our customers an edge over existing solutions.”"
        />
      </Container>
      <Container>
        <Column columns_mobile="12" columns_tablet="12" columns_desktop="12">
          <ItemsHolder title="How We Deliver Change">
            <CardServiceItem
              title="Consulting / Requirements Engineering"
              description="Bring your idea to life with a seasoned team that feels like yours. With experience across end-to-end transformation, Kickstage helps clients create strategies that unlock growth, improve financial performance, and innovate at speed."
              icon="icon-consulting"
            />
            <CardServiceItem
              title="Project Management of internal and external teams"
              description="Achieve faster business outcomes with intelligent project management. We help clients drive transformation by enriching the relationship between people and technology."
              icon="icon-project-management"
            />
            <CardServiceItem
              title="Training"
              description="Empower your IT teams to make the most of their talents. We specialise in the design of bespoke training solutions to drive growth through people and technology."
              icon="icon-book"
            />
            <CardServiceItem
              title="Web Frontend Development"
              description="Give your customers an outstanding user experience. We bring frontend to perfection, using the best tools and framework to build powerful web-enabled apps that deliver a seamless digital experience."
              icon="icon-monitor"
            />
            <CardServiceItem
              title="Mobile App Development"
              description="Build an app that brings innovation closer to your customers. With Kickstage, providing financial services to customers is now secure, integrated and protected."
              icon="icon-mobile-phone"
            />
            <CardServiceItem
              title="Backend Development"
              description="Great products require great backend solutions. With Kickstage, that’s what you get! We build scalable backend solutions that scale with your business needs for web, mobile and desktop platforms."
              icon="icon-backend-development"
            />
            <CardServiceItem
              title="Third Party API integration (e.g. FIX)"
              description="Maintain bug-free APIs and get your systems and apps working together with Kickstage. We offer custom API development services to integrate APIs that work with your digital landscape."
              icon="icon-api-integration"
            />
            <CardServiceItem
              title="Monitoring + Operations"
              description="Keep your business moving forward with pace and minimal disruption. From basic system checks to ensure high service performance, Kickstage can optimize and transform your business processes to be more data-driven, AI-powered and cloud-enabled."
              icon="icon-monitoring"
            />
          </ItemsHolder>
          <ItemsHolder title="Technologies used">
            <CardServiceItem
              title="Golang"
              description="Highly secure with managed memory architecture, Golang makes it easy for our developers to efficiently build safe and secure solutions."
              image={tempImage.goLangIcon}
            />
            <CardServiceItem
              title="RabbitMQ"
              description="RabbitMQ allows us to distribute low-latency updates to connected services."
              image={tempImage.rabbitmqIcon}
            />
            <CardServiceItem
              title="Node.JS and TypeScript"
              description="Node.JS and Typescript for scalable customer-facing APIs"
              image={tempImage.nodejsIcon}
            />
            <CardServiceItem
              title="PostgreSQL"
              description="Highly reliable and secure, we use PostgreSQL as our platform of choice to safely store and secure financial data."
              image={tempImage.postgreqlIcon}
            />
            <CardServiceItem
              title="Unit & Integration testing"
              description="We perform Unit and Integration testing with Jest, Cypress and other tools to deliver software that can be trusted in your most business-critical applications."
              image={tempImage.unitTestingIcon}
            />
          </ItemsHolder>
        </Column>
      </Container>
      <TextPhotoSection
        title="Manufacturing"
        description="Give your production lines a competitive advantage with Kickstage. See how we’re driving end-to-end transformation through smart-connected solutions for the manufacturing industry."
        image={tempImage.manufactoringImage}
        btnLink="/our-work/manufacturing-industry"
        btnText="Find out more"
        radialTextDecoration
        radialTextDecorationColor="yellow"
        radialTextDecorationPosition="top-right"
        extraSmallPaddingBottom
      />
      <CtaSection
        title="Let's Chat"
        label="What Can We Do For You?"
        link="/contact/"
      />
    </Layout>
  );
};

export default OurWork;
